// App.tsx
import React, { useState } from 'react';
import axios from 'axios';
import SearchInput from './SearchInput';
import ResultsDisplay from './ResultsDisplay';

// Define the shape of the result item
interface ResultItem {
    url: string;
    image: string;
    score: number;
}

const App: React.FC = () => {
    const [results, setResults] = useState<ResultItem[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const handleSearch = async (query: string, method: 'get' | 'post') => {
        try {

            setLoading(true); // Start loading state
            setError(null); // Reset error state

            if (method === 'get'){
                const trimmedQuery = query.trim();
                
                //GET
                const response = await axios.get(`/api/search`, { 
                    params: { description: trimmedQuery } 
                });

                if (Array.isArray(response.data)) { // Assuming results is the key
                    setResults(response.data);
                } else {
                    console.error('Unexpected data format:', response.data);
                    setResults([]); // Set to empty array or handle accordingly
                }
            }else{
                //POST
                const searchParams = {
                    image_similarity_search:query,
                    // similarity_threshold: 0,
                    cutoff_threshold: 0,
                    return_images: true,
                    return_metadata: false,
                    return_root_prims: false,
                    return_predictions: false,
                    return_in_scene_instances_prims: false,
                    return_vision_generated_metadata: false,
                    file_extension_include: 'usd*',
                };
                const response = await axios.post('/api/search', searchParams);
                setResults(response.data);
            }
        } catch (err) {
            if (axios.isAxiosError(err) && err.response) {
                // Handle known Axios error
                setError(err.response.data.error || 'An unknown error occurred');
            } else if (err instanceof Error) {
                setError(err.message);
            }
            else {
            setError("An unknown error occurred");
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <h1>Lowe's USD Search</h1>
            <SearchInput onSearch={handleSearch} />
            <ResultsDisplay results={results} />
            {loading && <p>Loading...</p>}
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
    );
};

function isBase64(str:any) {
    // Check if the string matches the Base64 pattern
    const base64Pattern = /^data:image\/(jpeg|png|gif);base64,/;
    return base64Pattern.test(str);
}

export default App;