// ResultsDisplay.tsx
import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

interface ResultItem {
    url: string;
    score: number;
    image: string; // Base64 encoded or URL
}

const ResultsDisplay: React.FC<{ results: ResultItem[] }> = ({ results }) => {
    // Check if results is an array
    if (!Array.isArray(results)) {
        return <p>Error: Results data is not in the expected format.</p>;
    }

    if (!results.length) {
        return <p>No results found.</p>;
    }

    return (
        <Grid container spacing={2}>
            {results.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} lg={2} key={index}>
                    <Paper elevation={3} style={{ padding: '16px', textAlign: 'center' }}>
                        {/* <img src={item.image} alt={`Result for ${item.url}`} style={{ maxWidth: '100%', height: 'auto' }} /> */}
                        {item.image && (
                            <img 
                                src={`data:image/jpeg;base64,${item.image}`} 
                                alt={`thumbnail for ${item.url}`}
                                style={{ maxWidth: '100%', height: 'auto' }} // Optional styling
                            />
                        )}
                        <p style={{
                            wordWrap: 'break-word',
                            overflowWrap: 'break-word',
                            margin: 0,
                            textAlign: 'left' 
                        }}>
                        <a href={item.url} style={{ color: 'blue', textDecoration: 'underline' }}>
                        {item.url.length > 10 ? `${item.url.slice(0,30)}...`: item.url}</a>
                        </p>
                        <p>Score: {item.score}</p>
                    </Paper>
                </Grid>
            ))}
        </Grid>
    );
};

export default ResultsDisplay;