// SearchInput.tsx
import React, { useState } from 'react';
import { TextField, Button, Grid, Paper } from '@mui/material';

const SearchInput: React.FC<{ onSearch: (query: string, method: 'get'|'post') => void }> = ({ onSearch }) => {
    const [textQuery, setTextQuery] = useState('');
    const [imageFile, setImageFile] = useState<File | null>(null);

    const handleTextSearch = () => {
        if (textQuery.trim()) {
            onSearch(textQuery.trim(), "get");
            setTextQuery('');
        }
    };

    const handleKeyDown = (event: { key: string; }) => {
        if (event.key === 'Enter') {
            handleTextSearch(); // Invoke search on Enter key press
        }
    };

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setImageFile(file);
            // if (file.type.startsWith('image/')) {
                convertToBase64(file)
                    .then(base64 => {
                        // Call your API with the Base64 encoded data
                        onSearch(base64, "post");
                    })
                .catch(error => {
                    console.error('Error converting file to Base64:', error);
                });
        }
    };

    const convertToBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file); // Read the file as a Data URL (Base64)
            reader.onload = () => {
                if (reader.result) {
                    resolve(reader.result as string); // Resolve with the Base64 string
                } else {
                    reject(new Error('Failed to read file'));
                }
            };
            reader.onerror = () => {
                reject(new Error('Error reading file'));
            };
        });
    };

    return (
        <Grid container spacing={0} alignItems="center">
            <Grid item xs={8} sm={6} component="div">
                <TextField
                    label="Enter text to search"
                    variant="outlined"
                    value={textQuery}
                    onChange={(e) => setTextQuery(e.target.value)}
                    onKeyDown={handleKeyDown}
                    inputProps={{ maxLength: 100 }} // Set max length to 100 characters
                    fullWidth // Make the text field take full width
                />
            </Grid>
            <Grid item xs={4} sm={3}>
                <Button variant="contained" onClick={handleTextSearch} style={{ height: '100%' }}>
                    Search
                </Button>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Paper style={{ padding: '8px', textAlign: 'center' }}>
                    <p style={{ margin: 0 }}>Choose the file for image search:</p>
                    <input 
                        type="file" 
                        accept="image/*" 
                        onChange={handleImageUpload} 
                        style={{ marginTop: '4px' }} // Add some space above the input
                    />
                </Paper>
            </Grid>
        </Grid>
    );
};

export default SearchInput;